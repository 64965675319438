/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*[Moz SEO Strategy - The One-hour guide to SEO](https://www.youtube.com/watch?v=8Pne_Pp2F3g&list=PLbKcy9p3mh_GyF0xNpp-QNqHpjmCpvam3)
*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "SEO Strategy Review Notes 2021"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "marketing and business goals", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "grow sales"), "\n", React.createElement(_components.li, null, "brand awareness"), "\n", React.createElement(_components.li, null, "funnel traffic"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "SEO strategy and tactics to achive marketing goals"), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "SEO strategy", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "low demand high conversion keywords (conversions)"), "\n", React.createElement(_components.li, null, "hight demand, low conversion (brand awareness)"), "\n", React.createElement(_components.li, null, "links and influencer engagment"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "first time someone hears about a brand unlikely to buy, seventh time more likely"), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "SEO is long term strategy to increase authority of a website"), "\n"), "\n", React.createElement(_components.h2, null, "Reference"), "\n", "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubePlaylistId: "PLbKcy9p3mh_GyF0xNpp-QNqHpjmCpvam3"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
